<template>
  <base-table
    class-name="table-handlers"
    :headers="headers"
    :items="handlersComp"
    :item-class="() => 'clickable-row'"
    :is-custom-header="false"
    :word-operations="['активность', 'активности', 'активностей']"
    :options="{
      sortBy: ['id'],
    }"
    disable-pagination
    hide-default-footer
    @click:row="openHandlerFormClick"
  >
    <template v-slot:[`item.actionData`]="{ item }">
      <v-row
        v-if="
          item.isSegmentAction &&
            item.action_json &&
            item.action_json.segment_id
        "
      >
        <v-col cols="auto">
          <segment-chip
            v-if="
              $_.findWhere(pickSegments, { id: item.action_json.segment_id })
            "
            class="segment-chip__action-data"
            max-width="400px"
            :name="
              $_.findWhere(pickSegments, { id: item.action_json.segment_id })
                .name
            "
            :color="
              $_.findWhere(pickSegments, { id: item.action_json.segment_id })
                .color
            "
            :is_public="$_.findWhere(pickSegments, {id: item.action_json.segment_id}).is_public"
          />
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          item.isBonusAction &&
            item.action_json &&
            item.action_json.bonus_res_id &&
            $_.findWhere(manualBonusRes, { id: item.action_json.bonus_res_id })
        "
      >
        <v-col cols="auto">
          <segment-chip
            class="segment-chip__action-data"
            max-width="400px"
            :name="
              $_.findWhere(manualBonusRes, {
                id: item.action_json.bonus_res_id,
              }).title +
                ': ' +
                {
                  MANUAL: item.action_json.value,
                  TO_MAX: 'До максимума',
                  TO_MIN: 'До нуля',
                }[item.action_json.bunus_value_type || 'MANUAL']
            "
            color="#EA971F"
          />
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          item.isSaleBonusAction &&
            item.action_json &&
            item.action_json.bonus_res_id &&
            $_.findWhere(buyBonusRes, { id: item.action_json.bonus_res_id })
        "
      >
        <v-col cols="auto">
          <segment-chip
            class="segment-chip__action-data"
            max-width="400px"
            :name="
              $_.findWhere(buyBonusRes, { id: item.action_json.bonus_res_id })
                .title
            "
            color="#EA971F"
          />
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          item.isNotificationAction &&
            item.action_json &&
            item.action_json.notification_id &&
            $_.findWhere(notificationPickList, {
              id: item.action_json.notification_id,
            })
        "
      >
        <v-col cols="auto">
          <segment-chip
            class="segment-chip__action-data"
            max-width="400px"
            :name="
              $_.findWhere(notificationPickList, {
                id: item.action_json.notification_id,
              }).name
            "
            color="#EA971F"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.actionText`]="{ item }">
      <div class="action__chips">
        <segment-chip
          class="chip__action-text"
          max-width="400px"
          :name="item.actionText"
          text-color="#fff"
          :background-color="actionTypesColors[item.action_type]"
        />
        <div
          class="chip__action-arrow"
          :style="`background: ${hexToRgbA(
            actionTypesColors[item.action_type],
            '0.15'
          )}`"
        >
          <v-icon :color="actionTypesColors[item.action_type]">
            $iconify_feather-arrow-right
          </v-icon>
        </div>
      </div>
    </template>
    <template v-slot:[`item.run_user`]="{ item }">
      <user-column
        :user="item.run_user || item.creater"
        :show-last-activity="false"
      />
    </template>
    <template v-slot:[`item.active`]="{ item }">
      <v-row justify="center">
        <v-col cols="auto">
          <v-switch
            v-model="item.active"
            :loading="item.changeActiveAction"
            :disabled="item.changeActiveAction"
            inset
            hide-details
            class="custom-switch"
            @click.stop=""
            @change="activeChange(item, $event)"
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon @click="openHandlerFormClick(item)">
        mdi-chevron-right
      </v-icon>
    </template>
    <!-- <template v-slot:foot>
      <tfoot>
        <tr>
          <td :colspan="headers.length">
            <v-btn
          color="secondary"
          :text="true"
          :ripple="false"
          style="margin-left:20px;"
          @click="createHandlerClick"
        >
          <v-icon left>
            $iconify_plus-circle-outlined
          </v-icon>
          Добавить обработчик
        </v-btn>
          </td>
        </tr>

      </tfoot>
    </template> -->
    <template v-slot:footer>
      <v-row style="margin-bottom:10px;">
        <v-col>
          <v-btn
            color="secondary"
            :text="true"
            :ripple="false"
            style="margin-left:20px;"
            @click="createHandlerClick"
          >
            <v-icon left>
              $iconify_plus-circle-outlined
            </v-icon>
            Добавить обработчик
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <!-- </v-data-table> -->
  </base-table>
</template>

<script>
  import Convertor from '@/mixins/convertor'
  import { mapGetters, mapActions } from 'vuex'
  import ProgramEventBroadcasterHandler from '@/models/program/broadcasterHandler'
  import Vue from 'vue'

  export default {
    components: {

      SegmentChip: () => import('@/components/segment/SegmentChip'),
      UserColumn: () => import('@/components/colums/UserColumn.vue'),
    },
    mixins: [Convertor],
    // model: {
    //   prop: 'handlers',
    //   event: 'update',
    // },
    props: {
      handlers: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        broadcasterInternal: null,
        headers: [
          { text: 'ID', align: 'start', value: 'id', width: '5em' },
          { text: 'Действие', value: 'actionText', width: '10em' },
          {
            text: 'Детали',
            value: 'actionData',
            filterable: false,
            sortable: false,
          },
          {
            text: 'Ответственный',
            value: 'run_user',
            filterable: false,
            sortable: false,
          },
          // { text: '', value: '', filterable: false, sortable: false }, //
          {
            text: 'Вкл/Выкл',
            value: 'active',
            align: 'center',
            width: '11em',
            filterable: false,
            sortable: false,
          },
          {
            text: ' ',
            value: 'actions',
            width: '1em',
            filterable: false,
            sortable: false,
          },
        ],
        // editedHandler: null,
        // showHandlerDialog: false,
        actionTypesColors: {
          BONUS_DEBIT: '#4FB82B',
          BONUS_CREDIT: '#4FB82B',
          SALE_BONUS_CREDIT: '#4FB82B',
          NOTIFICATION: '#709AB9',
          SEGMENT_IN: '#4FB82B',
          SEGMENT_OUT: '#ED1C24',
          LOG: '#709AB9',
        },
      }
    },
    computed: {
      ...mapGetters({
        pickSegments: 'crm/segment/pickSegments',
        manualBonusRes: 'company/bonus_resources/manualBonusRes',
        buyBonusRes: 'company/bonus_resources/buyBonusRes',
        notificationPickList: 'company/notifications/notificationPickList',
      }),
      handlersComp () {
        return this.handlers.map((item) => {
          // return new ProgramEventBroadcasterHandler(item) -- ломает реактивность
          // const model = new ProgramEventBroadcasterHandler(Object.copy(item))
          Vue.set(item, 'changeActiveAction', false)

          Vue.set(item, 'actionText', ProgramEventBroadcasterHandler.ACTION_ENUM.find(item.action_type).text)

          Vue.set(item, 'isSegmentAction', item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SEGMENT_IN.id ||
            item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SEGMENT_OUT.id)
          Vue.set(item, 'isBonusAction', item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_DEBIT.id ||
            item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_CREDIT.id)
          Vue.set(item, 'isSaleBonusAction', item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SALE_BONUS_CREDIT.id)
          Vue.set(item, 'isNotificationAction', item.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.NOTIFICATION.id)

          return item
        })
      },
    },
    watch: {

    },
    created () {

    },
    methods: {
      ...mapActions({
        SetActiveBroadcasterHandler: 'company/event_broadcasters/SetActiveBroadcasterHandler',
      }),
      createHandlerClick () {
        this.$emit('createHandlerClick')
      },
      openHandlerFormClick (item) {
        console.log('openHandlerFormClick', item)
        this.$emit('onItemClick', item)
      },
      async activeChange (item, active) {
        try {
          console.log('activeChange', item, active)
          item.changeActiveAction = true
          if (!item.isNew) {
            await this.SetActiveBroadcasterHandler({ handlerId: item.id, active })
          } else {
            item.active = active
          }
        } catch (error) {
          console.error(error)
          item.active = !item.active
        } finally {
          item.changeActiveAction = false
        }
      },
    },

  }
</script>

<style lang="scss" scoped>
@import '@/views/dashboard/form_component/_form-component.scss';

.action__chips {
  display: inline-flex;
  width: 100%;
  align-items: center;
  .chip__action-text {
    border-radius: 8px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    height: 21px;
    & ::v-deep {
      span {
        margin-left: 6px;
      }
    }
  }

  .chip__action-arrow {
    margin-left: 19px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    padding: 0!important;
    display: flex;
    justify-content: center;
  }
}

.segment-chip__action-data {
  align-items: center;
  font-size: 10.5px;
  padding: 1px 8px 1px 8px;
}

</style>
